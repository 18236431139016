<template>
  <router-view name="gnb" :key="this.$route.fullPath" />
  <router-view name="main" :key="this.$route.fullPath" />
  <router-view name="footer" :key="this.$route.fullPath" />
  <metainfo />
</template>

<script>
import ChannelService from "@/util/channelio.js";
export default {
  name: "App",
  metaInfo() {
    return {
      title: this.$t("meta.title"),
      description: this.$t("meta.description"),
      og: {
        title: this.$t("meta.title"),
        description: this.$t("meta.description"),
        type: "website",
      },
    };
  },
  mounted() {
    console.log(
      "%cWe Know Them",
      "font-size: 32px; color: #e74356; font-weight: bold"
    );

    const country_code = this.common.get_country_code_from_url();
    if (country_code == "KOR") {
      this.$i18n.locale = "ko";
    } else {
      this.$i18n.locale = "en";
    }

    let self = this;

    // 로그인 하고 국가 정보가 상이하면 다른 주소로 보내기
    if (this.storage.is_logged_in()) {
      // 국가가 상이하면...
      if (this.storage.get_user_country() != country_code) {
        let user_country = this.storage.get_user_country();
        let code2 = this.common
          .get_country_code2_by_code3(user_country)
          .toLowerCase();

        // 코드2가 존재하고, 배포 모드인 경우...
        if (code2 != "" && process.env.NODE_ENV == "production") {
          let url = "https://";
          if (code2 == "kr") url += "weknowthem.com";
          else {
            url += code2 + ".weknowthem.com";
          }

          // 경고 후 리다이렉트
          this.$swal
            .fire({
              text: self.$t("common.redirect_country"),
              icon: "warning",
            })
            .then(() => {
              self.storage.clear();
              window.location.href = url;
            });
        }
      }
    }

    let channeltalk_settings = {
      pluginKey: "9011b65f-7ac6-4511-8761-994d83c66541",
    };

    // 로그인 했다면 채널톡에 사용자 정보 설정
    let user_profile = {};
    if (this.storage.is_logged_in()) {
      user_profile = {
        name: this.storage.get_user_name(),
        email: this.storage.get_user_email(),
        country: this.storage.get_user_country(),
      };

      channeltalk_settings.memberId = this.storage.get_user_id();
      channeltalk_settings.profile = user_profile;
    }

    ChannelService.boot(channeltalk_settings);
  },
};
</script>

<style>
@import "./assets/css/popup.css";
@import "./assets/css/product.css";
@import "./assets/css/tooltip.css";
</style>
