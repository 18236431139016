<template>
  <div id="footer">
    <div class="inner">
      <div class="top">
        <div class="link">
          <div class="section">
            <h1>{{ this.$t("footer.service") }}</h1>
            <ul>
              <li>
                <router-link to="/notice">{{
                  this.$t("footer.notice")
                }}</router-link>
              </li>
              <li>
                <router-link to="/event">{{
                  this.$t("footer.event")
                }}</router-link>
              </li>
              <li>
                <router-link to="/partner/apply">{{
                  this.$t("footer.consignment")
                }}</router-link>
              </li>
              <li>
                <router-link to="/cs">{{
                  this.$t("footer.cs_center")
                }}</router-link>
              </li>
            </ul>
          </div>
          <!--// section -->

          <div class="section">
            <h1>{{ this.$t("footer.company") }}</h1>
            <ul>
              <li>
                <a href="//corp.weknowthem.com" target="_blank">{{
                  this.$t("footer.company_intro")
                }}</a>
              </li>
              <li>
                <a
                  href="https://weknowthem.notion.site/15f023d45805456484f1ca9d070372f9"
                  target="_blank"
                  >{{ this.$t("footer.recruit") }}</a
                >
              </li>
              <!-- <li>{{ this.$t("footer.blog") }}</li> -->
              <li>
                <a href="//nft.weknowthem.com" target="_blank">{{
                  this.$t("footer.nft")
                }}</a>
              </li>
            </ul>
          </div>
          <!--// section -->
        </div>

        <div class="company">
          <div class="logo">
            <img
              src="../assets/img/logo_600.png"
              :alt="this.$t('footer.logo_alt')"
            />
          </div>
          <div class="info">
            <p class="bold">
              &copy;2021-{{ this.current_year }}
              {{ this.$t("footer.company_en") }}
              <span v-if="this.country == 'KOR'">{{
                this.$t("footer.company_ko")
              }}</span>
            </p>
            <p>{{ this.$t("footer.address") }}</p>
            <p>{{ this.$t("footer.ceo") }}</p>
            <p>{{ this.$t("footer.tel") }}</p>
            <p>{{ this.$t("footer.business_no") }}</p>
            <p>{{ this.$t("footer.online_no") }}</p>
          </div>
          <div class="policy">
            <ul>
              <li>
                <router-link to="/policy/term">{{
                  this.$t("footer.agreement")
                }}</router-link>
              </li>
              <li>
                <router-link to="/policy/privacy">{{
                  this.$t("footer.privacy_policy")
                }}</router-link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <!--// top -->

      <div class="bottom">
        <div class="email">
          <a href="mailto:info@weknowthem.com">info@weknowthem.com</a>
        </div>
        <div class="sns">
          <ul>
            <li>
              <a
                href="https://www.facebook.com/weknowthem.official/"
                target="_blank"
                ><div class="facebook"></div
              ></a>
            </li>
            <li>
              <a
                href="https://www.instagram.com/weknowthem.official/"
                target="_blank"
                ><div class="instagram"></div
              ></a>
            </li>
            <li>
              <a href="https://twitter.com/weknowthem_ofcl" target="_blank"
                ><div class="twitter"></div
              ></a>
            </li>
          </ul>
        </div>

        <div class="country">
          <img
            class="flag"
            :src="require('@/assets/img/flag/' + this.country + '.svg')"
          />
        </div>
      </div>
      <!--// bottom -->
    </div>
    <!--// inner -->
  </div>
  <!--// footer -->
</template>

<script>
export default {
  name: "Footer",
  data: function () {
    return {
      current_year: 0,
      country: "KOR",
    };
  },
  mounted() {
    this.current_year = new Date().getFullYear();
    this.country = this.common.get_country_code_from_url();
  },
};
</script>

<style scoped>
#footer {
  border-top: 1px solid #efefef;

  font-size: 1rem;
  font-weight: 400;
  color: #333;
  background: #f9f9f9;
}
#footer a {
  color: #333;
  text-decoration: none;
}
#footer a:hover {
  text-decoration: underline;
}
#footer > .inner {
  width: 1200px;
  max-width: 100%;
  margin: 0 auto;
  padding: 2rem 0;
}

#footer > .inner > .top {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: row-reverse;

  padding: 1rem 1rem 3rem 1rem;
  border-bottom: #ccc;
}

#footer > .inner > .top > .company {
  width: 60%;
}
#footer > .inner > .top > .company > .logo {
  margin: 0 0 2rem 0;
}
#footer > .inner > .top > .company > .logo > img {
  display: block;
  width: 48px;
}
#footer > .inner > .top > .company > .info {
  margin: 0 0 1rem 0;
}
#footer > .inner > .top > .company > .info > p {
  padding: 0;
  margin: 0 0 0.6rem 0;
}
#footer > .inner > .top > .company > .info > p.bold {
  font-weight: 700;
}
#footer > .inner > .top > .company > .policy {
  margin: 0;
}
#footer > .inner > .top > .company > .policy > ul {
  list-style-type: none;
  padding: 0;
  margin: 0;

  display: flex;
  justify-content: flex-start;
  align-items: center;
}
#footer > .inner > .top > .company > .policy > ul > li {
  margin: 0 1rem 0 0;
}

#footer > .inner > .top > .link {
  position: relative;
  width: 30%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin: 0 0 2rem 0;
}
#footer > .inner > .top > .link > .section {
  width: 50%;
  padding: 0 1rem;
}
#footer > .inner > .top > .link > .section > h1 {
  font-size: 1.2rem;
  font-weight: 700;

  padding: 0;
  margin: 0 0 1rem 0;
}
#footer > .inner > .top > .link > .section > ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
#footer > .inner > .top > .link > .section > ul > li {
  display: block;
  padding: 0;
  margin: 0 0 0.6rem 0;
}

#footer > .inner > .bottom {
  position: relative;
  margin: 0;
  padding: 1rem;
  border-top: 1px solid #ccc;

  display: flex;
  justify-content: flex-start;
  align-items: center;
}
#footer > .inner > .bottom > .email {
  font-weight: 700;
  padding: 0 2rem 0 0;
  margin: 0 2rem 0 0;
  border-right: 1px solid #dedede;
}

#footer > .inner > .bottom > .sns > ul {
  list-style-type: none;
  padding: 0;
  margin: 0;

  display: flex;
  justify-content: flex-start;
  align-items: center;
}
#footer > .inner > .bottom > .sns > ul > li {
  padding: 0;
  margin-right: 1rem;
}
#footer > .inner > .bottom > .sns > ul > li div {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background: #dedede;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: auto 50%;
}
#footer > .inner > .bottom > .sns > ul > li div.facebook {
  background-image: url("../assets/img/icon_facebook.svg");
}
#footer > .inner > .bottom > .sns > ul > li div.instagram {
  background-image: url("../assets/img/icon_instagram.svg");
}
#footer > .inner > .bottom > .sns > ul > li div.twitter {
  background-image: url("../assets/img/icon_twitter.svg");
}

#footer > .inner > .bottom > .country {
  position: absolute;
  right: 1rem;
  bottom: 50%;
  transform: translate(0, 50%);
}
#footer > .inner > .bottom > .country > .flag {
  display: block;
  width: 2.2rem;
  border: 1px solid #dedede;
}

@media (max-width: 800px) {
  #footer > .inner {
    padding-bottom: 4rem;
  }
  #footer > .inner > .top {
    display: block;
    padding: 1rem 1rem 3rem 1rem;
    border-bottom: #ccc;
  }
  #footer > .inner > .top > .company {
    width: auto;
  }
  #footer > .inner > .top > .link {
    width: auto;
    display: block;
    margin: 0 0 2rem 0;
  }
  #footer > .inner > .top > .link > .section {
    width: auto;
    padding: 0;
  }
  #footer > .inner > .top > .link > .section > ul {
    margin: 0 0 2.4rem 0;
  }
  #footer > .inner > .top > .link > .section > ul > li {
    margin: 0 1rem 0.6rem 0;
  }

  #footer > .inner > .bottom {
    display: block;
    text-align: center;
  }
  #footer > .inner > .bottom > .email {
    padding: 0;
    margin: 0 0 1rem 0;
    border: none;
  }
  #footer > .inner > .bottom > .sns {
    margin: 2rem 0 0 0;
  }
  #footer > .inner > .bottom > .sns > ul {
    justify-content: center;
  }
  #footer > .inner > .bottom > .sns > ul > li {
    padding: 0;
    margin: 0 0.4rem;
  }
  #footer > .inner > .bottom > .country {
    position: relative;
    right: auto;
    bottom: auto;
    transform: none;

    display: block;
    margin: 2rem auto 0 auto;
  }
  #footer > .inner > .bottom > .country > .flag {
    margin: 0 auto;
  }
}
</style>
