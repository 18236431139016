export class Storage {
  static key_user_id = "WKT_USER_ID";
  static key_user_token = "WKT_USER_TOKEN";
  static key_user_name = "WKT_USER_NAME";
  static key_user_email = "WKT_USER_EMAIL";
  static key_user_country = "WKT_USER_COUNTRY";
  static key_user_cart = "WKT_USER_CART";
  static key_user_avatar = "WKT_USER_AVATAR";

  static is_logged_in() {
    let ret = localStorage.getItem(this.key_user_token);
    if (typeof ret == "undefined" || ret == null) return false;
    else return true;
  }

  static get_user_token() {
    let ret = localStorage.getItem(this.key_user_token);
    if (typeof ret == "undefined" || ret == null) return "";
    else return ret;
  }

  static get_user_id() {
    let ret = localStorage.getItem(this.key_user_id);
    if (typeof ret == "undefined" || ret == null) return "";
    else return ret;
  }

  static get_user_name() {
    let ret = localStorage.getItem(this.key_user_name);
    if (typeof ret == "undefined" || ret == null) return "";
    else return ret;
  }

  static get_user_email() {
    let ret = localStorage.getItem(this.key_user_email);
    if (typeof ret == "undefined" || ret == null) return "";
    else return ret;
  }

  static get_user_country() {
    let ret = localStorage.getItem(this.key_user_country);
    if (typeof ret == "undefined" || ret == null) return "";
    else return ret;
  }

  static get_user_cart() {
    let str_cart = localStorage.getItem(this.key_user_cart);
    if (typeof str_cart == "undefined" || str_cart == null) return [];

    try {
      let ret = JSON.parse(str_cart);
      return ret;
    } catch (error) {
      console.log(error);
      return [];
    }
  }

  static get_user_avatar() {
    let ret = localStorage.getItem(this.key_user_avatar);
    if (typeof ret == "undefined" || ret == null || ret == "null") return "";
    else return ret;
  }

  static get_user_avatar_url() {
    let image = localStorage.getItem(this.key_user_avatar);
    if (typeof image == "undefined" || image == null || image == "null") {
      return "";
    } else {
      let ret =
        process.env.VUE_APP_API_URL +
        "/resource/get/avatar/user/" +
        localStorage.getItem(this.key_user_id) +
        "/" +
        image;
      return ret;
    }
  }

  static set_user_token(user_token) {
    localStorage.setItem(this.key_user_token, user_token);
  }

  static set_user_info(user_info) {
    localStorage.setItem(this.key_user_id, user_info.user_id);
    localStorage.setItem(this.key_user_name, user_info.user_name);
    localStorage.setItem(this.key_user_email, user_info.user_email);
    localStorage.setItem(this.key_user_country, user_info.user_country);
    localStorage.setItem(this.key_user_avatar, user_info.avatar_thumbnail);
  }

  static set_user_avatar(avatar_thumbnail) {
    localStorage.setItem(this.key_user_avatar, avatar_thumbnail);
  }

  static set_user_cart(cart) {
    let str_cart = JSON.stringify(cart);
    localStorage.setItem(this.key_user_cart, str_cart);
  }

  static clear_user_token() {
    localStorage.removeItem(this.key_user_token);
  }

  static clear_user_info() {
    localStorage.removeItem(this.key_user_id);
    localStorage.removeItem(this.key_user_name);
    localStorage.removeItem(this.key_user_email);
    localStorage.removeItem(this.key_user_country);
    localStorage.removeItem(this.key_user_avatar);
  }

  static clear_user_cart() {
    localStorage.removeItem(this.key_user_cart);
  }

  static clear() {
    console.log("token all removed.");
    localStorage.removeItem(this.key_user_token);
    localStorage.removeItem(this.key_user_id);
    localStorage.removeItem(this.key_user_name);
    localStorage.removeItem(this.key_user_email);
    localStorage.removeItem(this.key_user_country);
    localStorage.removeItem(this.key_user_avatar);

    localStorage.removeItem(this.key_user_cart);
  }
}
