<template>
  <div class="main">
    <div class="banner">
      <swiper
        :slidesPerView="1"
        :centeredSlides="true"
        :loop="true"
        :pagination="{
          clickable: true,
        }"
        :autoplay="{ delay: 5000 }"
        :navigation="true"
        :modules="modules"
      >
        <swiper-slide
          v-for="(value, index) in this.slide_data"
          :key="index"
          :style="{
            backgroundImage: 'url(' + value.banner_image_url + ')',
          }"
          @click="this.click_banner_link(value.banner_link)"
        >
          <div class="inner">
            <div
              class="foreground"
              :style="{
                backgroundImage:
                  'url(' + value.banner_foreground_image_url + ')',
              }"
            ></div>
            <div class="title">
              <h1>{{ value.banner_title }}</h1>
              <h2>{{ value.banner_subtitle }}</h2>
            </div>
          </div>
        </swiper-slide>
        <div class="overleft"></div>
        <div class="overright"></div>
      </swiper>
    </div>

    <div class="body">
      <!-- MD's choice -->
      <div class="list best">
        <h1>{{ this.$t("main.md_choice") }}</h1>
        <ul class="product">
          <li v-for="(value, index) in this.product_list_md" v-bind:key="index">
            <div
              class="image"
              :style="{
                backgroundImage:
                  'url(' +
                  this.common.get_product_image_url(
                    value.product_id,
                    value.product_images
                  ) +
                  ')',
              }"
              @click="this.$router.push('/product/detail/' + value.product_id)"
            ></div>
            <div class="desc">
              <div class="seller">{{ value.seller_name }}</div>
              <div class="name">
                <router-link
                  :to="{
                    name: 'detail',
                    params: { product_id: value.product_id },
                  }"
                  >{{ value.product_name }}</router-link
                >
              </div>
              <div class="price">
                <span
                  class="percent"
                  v-show="value.retail_price - value.sale_price > 0"
                  >{{
                    this.common.number_format(
                      Math.floor(
                        ((value.retail_price - value.sale_price) /
                          value.retail_price) *
                          100.0
                      )
                    )
                  }}%</span
                >
                <span class="sale">{{
                  this.common.currency_format(value.sale_price, value.unit)
                }}</span>
                <span
                  class="avatar"
                  v-if="value.item_id != null && value.item_id > 0"
                ></span>
              </div>
            </div>
          </li>
        </ul>
      </div>
      <!--// MD's choice -->

      <!-- Avatar Product -->
      <div class="list avatar">
        <h1>
          <div class="hanger"></div>
          <span class="title">{{ this.$t("main.avatar_coordi") }}</span>
          <span class="desc">{{ this.$t("main.avatar_coordi_desc") }}</span>
        </h1>
        <ul class="product">
          <li
            v-for="(value, index) in this.avatar_product_data"
            v-bind:key="index"
          >
            <div
              class="image"
              :style="{
                backgroundImage:
                  'url(' +
                  this.common.get_product_image_url(
                    value.product_id,
                    value.product_images
                  ) +
                  ')',
              }"
              @click="this.$router.push('/product/detail/' + value.product_id)"
            ></div>
            <div class="desc">
              <div class="seller">{{ value.seller_name }}</div>
              <div class="name">
                <router-link
                  :to="{
                    name: 'detail',
                    params: { product_id: value.product_id },
                  }"
                  >{{ value.product_name }}</router-link
                >
              </div>
              <div class="price">
                <span
                  class="percent"
                  v-show="value.retail_price - value.sale_price > 0"
                  >{{
                    this.common.number_format(
                      Math.floor(
                        ((value.retail_price - value.sale_price) /
                          value.retail_price) *
                          100.0
                      )
                    )
                  }}%</span
                >
                <span class="sale">{{
                  this.common.currency_format(value.sale_price, value.unit)
                }}</span>
                <span
                  class="avatar"
                  v-if="value.item_id != null && value.item_id > 0"
                ></span>
              </div>
            </div>
          </li>
        </ul>
      </div>
      <!--// Avatar Product -->

      <!-- 중간 배너 -->
      <div class="list middle_banner">
        <ul>
          <li
            v-for="(value, index) in this.middle_banner_data"
            :key="index"
            @click="this.click_banner_link(value.banner_link)"
          >
            <img :src="value.banner_image_url" />
          </li>
        </ul>
      </div>
      <!--// 중간 배너 -->

      <!-- New Arriaval -->
      <div class="list new">
        <h1>
          <span class="title">{{ this.$t("main.new_arrival") }}</span>
          <div class="more" @click="this.$router.push('/new')"></div>
        </h1>
        <ul class="product">
          <li
            v-for="(value, index) in this.product_list_new"
            v-bind:key="index"
          >
            <div
              class="image"
              :style="{
                backgroundImage:
                  'url(' +
                  this.common.get_product_image_url(
                    value.product_id,
                    value.product_images
                  ) +
                  ')',
              }"
              @click="this.$router.push('/product/detail/' + value.product_id)"
            ></div>
            <div class="desc">
              <div class="seller">{{ value.seller_name }}</div>
              <div class="name">
                <router-link
                  :to="{
                    name: 'detail',
                    params: { product_id: value.product_id },
                  }"
                  >{{ value.product_name }}</router-link
                >
              </div>
              <div class="price">
                <span
                  class="percent"
                  v-show="value.retail_price - value.sale_price > 0"
                  >{{
                    this.common.number_format(
                      Math.floor(
                        ((value.retail_price - value.sale_price) /
                          value.retail_price) *
                          100.0
                      )
                    )
                  }}%</span
                >
                <span class="sale">{{
                  this.common.currency_format(value.sale_price, value.unit)
                }}</span>
                <span
                  class="avatar"
                  v-if="value.item_id != null && value.item_id > 0"
                ></span>
              </div>
            </div>
          </li>
        </ul>
      </div>
      <!--// New Arriaval -->

      <!-- Instagram -->
      <!-- <div class="list instagram">
        <h1>
          <a
            href="https://www.instagram.com/weknowthem.official/"
            target="_blank"
          >
            <img src="@/assets/img/icon_instagram_color.svg" /><span
              >weknowthem.official</span
            ></a
          >
        </h1>
        <ul class="instagram">
          <li v-for="(value, index) in this.instagram_data" :key="index">
            <div
              class="image"
              :style="{ backgroundImage: 'url(' + value.image_url + ')' }"
              @click="this.click_instagram_feed(value.permalink)"
            ></div>
            <div class="caption">{{ value.caption }}</div>
          </li>
        </ul>
      </div> -->
      <!--// Instagram -->
    </div>
  </div>

  <Spinner v-show="processing" />
</template>

<script>
import { Navigation, Pagination, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue/swiper-vue.js";

import "swiper/swiper-bundle.min.css";
import "swiper/modules/navigation/navigation.min.css";
import "swiper/modules/pagination/pagination.min.css";
import "swiper/modules/scrollbar/scrollbar.min.css";

export default {
  name: "Main",
  components: {
    Swiper,
    SwiperSlide,
  },
  data: function () {
    return {
      process_queue: [],

      group_id_md: 221007154210259, // MD's choice 그룹 아이디
      product_list_md: [], // MD's choice 리스트

      group_id_new: 221007154221118, // New Arrival 그룹 아이디
      product_list_new: [],

      avatar_product_data: [], // 아바타 코디 상품

      // 메인 배너
      slide_data: [
        {
          banner_title: "",
          banner_subtitle: "",
          banner_link: "",
          banner_image_url: "",
          banner_foreground_image_url: "",
        },
      ],

      middle_banner_data: [],

      instagram_data: [], // 인스타그램 데이터
    };
  },
  computed: {
    processing() {
      if (this.process_queue.length <= 0) return false;
      else return true;
    },
  },
  setup() {
    return {
      modules: [Navigation, Pagination, Autoplay],
    };
  },
  mounted() {
    this.init_data();
    this.load_banner();
    this.load_middle_banner();

    // this.load_instagram();
  },
  methods: {
    init_data: function () {
      this.load_product_group_data(this.group_id_md, this.product_list_md);
      this.load_product_group_data(this.group_id_new, this.product_list_new);

      this.load_avatar_product_data();
    },
    load_product_group_data: function (group_id, result_data) {
      let self = this;
      self.process_queue.push(1);

      this.axios
        .post(
          process.env.VUE_APP_API_URL + "/product/group",
          {
            group_id: group_id,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then(function (response) {
          // 초기화
          result_data.splice(0, result_data.length);

          for (let i = 0; i < response.data.length; i++) {
            result_data.push(response.data[i]);
          }
        })
        .catch(function (error) {
          alert(error);
        })
        .finally(function () {
          self.process_queue.pop();
        }); //-- axios
    }, //-- load_product_group_data

    // 아바타 코디 상품 가져오기
    load_avatar_product_data: function () {
      let self = this;
      self.process_queue.push(1);

      let search_filter = {
        avatar: ["YES"],
      };

      this.axios
        .post(
          process.env.VUE_APP_API_URL + "/product/list",
          {
            filter: search_filter,
            page: 1,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then(function (response) {
          let data = response.data;

          self.avatar_product_data = [];
          for (let i = 0; i < data.length && i < 12; i++) {
            self.avatar_product_data.push(data[i]);
          }
        })
        .catch(function (error) {
          console.log(error);
        })
        .finally(function () {
          self.process_queue.pop();
        }); //!-- axios
    }, //-- load_avatar_product_data

    // 메인 배너 가져오기
    load_banner: function () {
      let self = this;
      self.process_queue.push(1);

      this.axios
        .post(
          process.env.VUE_APP_API_URL + "/banner/list",
          {
            banner_country: self.common.get_country_code_from_url(),
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then(function (response) {
          // 초기화
          self.slide_data = [];

          for (let i = 0; i < response.data.length; i++) {
            response.data[i].banner_image_url =
              process.env.VUE_APP_API_URL +
              "/resource/get/banner/" +
              response.data[i].banner_id +
              "/" +
              response.data[i].banner_image;

            response.data[i].banner_foreground_image_url = "";
            if (response.data[i].banner_foreground_image != null) {
              response.data[i].banner_foreground_image_url =
                process.env.VUE_APP_API_URL +
                "/resource/get/banner/" +
                response.data[i].banner_id +
                "/" +
                response.data[i].banner_foreground_image;
            }

            self.slide_data.push(response.data[i]);
          }
        })
        .catch(function (error) {
          alert(error);
        })
        .finally(function () {
          self.process_queue.pop();
        }); //-- axios
    }, //-- load_banner

    // 중간 배너 가져오기
    load_middle_banner: function () {
      let self = this;
      self.process_queue.push(1);

      this.axios
        .post(
          process.env.VUE_APP_API_URL + "/banner/middle/list",
          {
            banner_country: self.common.get_country_code_from_url(),
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then(function (response) {
          // 초기화
          self.middle_banner_data = [];

          for (let i = 0; i < response.data.length; i++) {
            response.data[i].banner_image_url =
              process.env.VUE_APP_API_URL +
              "/resource/get/banner/" +
              response.data[i].banner_id +
              "/" +
              response.data[i].banner_image;

            self.middle_banner_data.push(response.data[i]);
          }
        })
        .catch(function (error) {
          alert(error);
        })
        .finally(function () {
          self.process_queue.pop();
        }); //-- axios
    }, //-- load_middle_banner

    click_banner_link: function (link) {
      if (typeof link != "undefined" && link != "") {
        this.$router.push(link);
      }
    },

    // 인스타그램 액세스 토큰 가져와서 피드 가져오기...(폐기예정)
    load_instagram: function () {
      let self = this;
      self.process_queue.push(1);

      this.axios
        .post(
          process.env.VUE_APP_API_URL + "/instagram/token",
          {},
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then(function (response) {
          if (typeof response.data.token == "undefined") return;

          let token = response.data.token;
          self.get_instagra_feed(token);
        })
        .catch(function (error) {
          alert(error);
        })
        .finally(function () {
          self.process_queue.pop();
        }); //-- axios
    }, //-- load_instagram

    // 인스타그램 API로 피드 가져오기... (폐기 예정)
    get_instagra_feed: function (token) {
      let self = this;
      self.process_queue.push(1);

      let url =
        "https://graph.instagram.com/me/media?fields=id,caption,media_type,media_url,permalink,username,timestamp&access_token=" +
        token;

      fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then((result) => {
          try {
            let data = result.data;

            self.instagram_data.splice(0, self.instagram_data.length);
            for (let i = 0; i < data.length && i < 8; i++) {
              // image_url에 이미지 경로 할당 (비디오일 경우 대비)
              data[i].image_url = data[i].media_url;
              if (data.media_type == "VIDEO") {
                data[i].image_url = data[i].thumbnail_url;
              }

              self.instagram_data.push(data[i]);
            }
          } catch (error) {
            console.log(error);
          }
        })
        .finally(() => {
          self.process_queue.pop();
        });
    }, //-- get_instagra_feed

    click_instagram_feed: function (url) {
      window.open(url, "_blank");
    },
  },
};
</script>

<style scoped>
.main {
  padding: 0;
  margin: 0;
}
.main > .banner {
  position: relative;
  width: auto;
  height: 480px;
  max-height: 60vh;
  background-color: #dedede;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

.main > .banner:deep(.swiper) {
  width: 100%;
  height: 100%;
}

.main > .banner:deep(.swiper-slide) {
  width: 100%;
  max-width: 100%;
  height: 100%;
  text-align: center;
  font-size: 18px;
  background: #fff;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.main > .banner:deep(.swiper-slide .inner) {
  position: relative;
  width: 1200px;
  max-width: 100%;
  height: 100%;

  margin: 0 auto;
}
.main > .banner:deep(.swiper-slide .inner > .title) {
  position: absolute;
  left: 0;
  bottom: 50%;
  transform: translate(0, 50%);
  padding: 1rem;

  width: 50%;
  max-width: 100%;

  text-align: left;
  color: #fff;
  text-shadow: 0 0 4px rgba(0, 0, 0, 0.45);
}
.main > .banner:deep(.swiper-slide .inner > .title > h1) {
  font-size: 2.8rem;
  font-weight: 900;
  padding: 0;
  margin: 0.8rem 0;
}
.main > .banner:deep(.swiper-slide .inner > .title > h2) {
  font-size: 1.6rem;
  font-weight: 500;
  padding: 0;
  margin: 0.8rem 0;
}
.main > .banner:deep(.swiper-slide .inner > .foreground) {
  position: absolute;
  right: 0;
  top: 0;

  width: 100%;
  height: 100%;

  background-position: right center;
  background-repeat: no-repeat;
  background-size: auto 100%;
}

.main > .banner > .swiper > .overleft,
.main > .banner > .swiper > .overright {
  position: absolute;
  top: 0;
  width: calc((100% - 1200px) / 2);
  min-width: 0;
  height: 100%;

  background: rgba(0, 0, 0, 0.32);
  z-index: 1;

  display: none;
}
.main > .banner > .swiper > .overleft {
  left: 0;
}
.main > .banner > .swiper > .overright {
  right: 0;
}

.main > .banner :deep(.swiper .swiper-button-prev),
.main > .banner :deep(.swiper .swiper-button-next) {
  color: #fff;
  /* background: #333;
  background: #e74356; */
  background: transparent;
  width: 3.6rem;
  height: 3.6rem;
  border-radius: 50%;
}
.main > .banner :deep(.swiper .swiper-button-prev) {
  /* left: calc((100% - 1200px) / 2);
  transform: translateX(-50%); */
  left: 2rem;
  text-shadow: 0 0 3px rgba(0, 0, 0, 0.45);
}
.main > .banner :deep(.swiper .swiper-button-next) {
  /* right: calc((100% - 1200px) / 2);
  transform: translateX(50%); */
  right: 2rem;
  text-shadow: 0 0 3px rgba(0, 0, 0, 0.45);
}
.main > .banner :deep(.swiper .swiper-button-prev::after),
.main > .banner :deep(.swiper .swiper-button-next::after) {
  font-size: 2.2rem;
  font-weight: 700;
}

.main > .banner :deep(.swiper-pagination-bullet-active) {
  background: #fff;
  background: #e74356;
}

.main > .body {
  width: 1200px;
  max-width: 100%;
  margin: 0 auto;
  padding: 2rem 1rem;
}
.main > .body > .list {
  margin: 0 0 4rem 0;
  margin: 4rem 0 6rem 0;
}
.main > .body > .list > h1 {
  font-size: 1.6rem;
  font-weight: 700;
  padding: 0;
  margin: 0 0 1.4rem 0;
}

.main > .body > .list > h1 > span.title {
  display: inline-block;
  vertical-align: middle;
}
.main > .body > .list > h1 > .more {
  display: inline-block;
  vertical-align: middle;
  width: 1.6rem;
  height: 1.6rem;
  margin-left: 0.8rem;

  border-radius: 50%;

  background-color: #dedede;
  background-image: url("../../assets/img/icon_right.svg");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: auto 60%;

  cursor: pointer;
}

.main > .body > .list.avatar > h1 > .hanger {
  display: inline-block;
  vertical-align: middle;
  width: 1.6rem;
  height: 1.6rem;
  margin: 0 0.4rem 0 0;

  background-image: url("../../assets/img/icon_hanger.svg");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
}
.main > .body > .list.avatar > h1 > .title {
  display: inline-block;
  vertical-align: middle;
  margin: 0 0.6rem 0 0;
}
.main > .body > .list.avatar > h1 > .desc {
  display: inline-block;
  vertical-align: middle;
  color: #808080;
  font-weight: 400;
}

.main > .body > .list.middle_banner > ul {
  list-style-type: none;
  margin: 2rem 0;
  padding: 0;

  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  flex-wrap: wrap;
}
.main > .body > .list.middle_banner > ul > li {
  position: relative;
  width: calc((100% - 1rem) / 2);
  margin: 0 1rem 1rem 0;
  cursor: pointer;
}
.main > .body > .list.middle_banner > ul > li:nth-child(2n) {
  margin: 0 0 1rem 0;
}
.main > .body > .list.middle_banner > ul > li > img {
  display: block;
  max-width: 100%;
}

.main > .body > .list.instagram > h1 {
  text-align: center;
}
.main > .body > .list.instagram > h1 img {
  display: inline-block;
  vertical-align: middle;
  height: 1.8rem;
  margin-right: 0.8rem;
}
.main > .body > .list.instagram span {
  display: inline-block;
  vertical-align: middle;
}
.main > .body > .list.instagram > h1 a {
  color: #000;
  text-decoration: none;
}

.main > .body > .list > ul.instagram {
  list-style-type: none;
  padding: 0;
  margin: 2rem 0 0 0;

  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  flex-wrap: wrap;
}
.main > .body > .list > ul.instagram > li {
  position: relative;
  width: calc((100% - 1rem - 1rem - 1rem) / 4);
  margin: 0 1rem 1rem 0;
  background: #fff;
  /* box-shadow: 0 3px 12px rgba(0, 0, 0, 0.24); */
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s linear;
}
.main > .body > .list > ul.instagram > li:nth-child(4n) {
  margin: 0 0 1rem 0;
}

@media (hover: hover) and (pointer: fine) {
  .main > .body > .list > ul.instagram > li:hover {
    transform: translateY(-3px);
    /* box-shadow: 0 2px 8px rgba(0, 0, 0, 0.34); */
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.16), 0 2px 5px rgba(0, 0, 0, 0.23);
  }
}

.main > .body > .list > ul.instagram > li > .image {
  position: relative;
  background-color: #dedede;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;

  cursor: pointer;
}
.main > .body > .list > ul.instagram > li > .image::before {
  content: "";
  display: block;
  padding-top: 100%;
}
.main > .body > .list > ul.instagram > li > .caption {
  font-size: 1rem;
  color: #000;
  padding: 0.8rem 1rem 1rem 1rem;
  white-space: pre-line;

  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}

@media (max-width: 1200px) {
  .main > .banner :deep(.swiper .swiper-button-prev),
  .main > .banner :deep(.swiper .swiper-button-next) {
    display: none;
  }
}

@media (max-width: 800px) {
  .main > .body > .list > ul.instagram > li {
    width: calc((100% - 1rem - 1rem) / 3);
  }
  .main > .body > .list > ul.instagram > li:nth-child(4n) {
    margin: 0 1rem 1rem 0;
  }
  .main > .body > .list > ul.instagram > li:nth-child(3n) {
    margin: 0 0 1rem 0;
  }
  .main > .body > .list > ul.instagram > li:nth-child(n + 7) {
    display: none;
  }

  .main > .body > .list.middle_banner > ul > li {
    width: 100%;
    margin: 0 0 1rem 0;
  }
}

@media (max-width: 600px) {
  .main > .banner:deep(.swiper-slide .inner > .title) {
    width: 100%;
    left: 0;
    bottom: 2.8rem;
    transform: none;
    padding: 0 0 0 2rem;
  }
  .main > .banner:deep(.swiper-slide .inner > .foreground) {
    background-position: center center;
    background-size: cover;
  }

  .main > .body > .list > ul.instagram > li {
    width: calc((100% - 1rem) / 2);
  }
  .main > .body > .list > ul.instagram > li:nth-child(4n) {
    margin: 0 1rem 1rem 0;
  }
  .main > .body > .list > ul.instagram > li:nth-child(3n) {
    margin: 0 1rem 1rem 0;
  }
  .main > .body > .list > ul.instagram > li:nth-child(2n) {
    margin: 0 0 1rem 0;
  }
  .main > .body > .list > ul.instagram > li:nth-child(n + 5) {
    display: none;
  }
}
</style>
