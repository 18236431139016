import { createRouter, createWebHistory } from "vue-router";
import Main from "../views/layout/Main.vue";
import GNB from "../components/GNB.vue";
import Footer from "../components/Footer.vue";
import NotFound from "../components/NotFound.vue";

const routes = [
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
  {
    path: "/404",
    name: "not_found",
    components: {
      main: NotFound,
    },
  },
  {
    path: "/",
    name: "main",
    components: {
      gnb: GNB,
      main: Main,
      footer: Footer,
    },
  },
  {
    path: "/new",
    name: "new",
    components: {
      gnb: GNB,
      main: () => import("../views/layout/New.vue"),
      footer: Footer,
    },
  },
  {
    path: "/sale",
    name: "sale",
    components: {
      gnb: GNB,
      main: () => import("../views/layout/Sale.vue"),
      footer: Footer,
    },
  },
  {
    path: "/ranking",
    name: "ranking",
    components: {
      gnb: GNB,
      main: () => import("../views/layout/Ranking.vue"),
      footer: Footer,
    },
  },
  {
    path: "/event",
    name: "event",
    components: {
      gnb: GNB,
      main: () => import("../views/event/Event.vue"),
      footer: Footer,
    },
    children: [
      {
        path: "",
        name: "event_list",
        component: () => import("../views/event/List.vue"),
      },
      {
        path: ":event_id",
        name: "event_detail",
        component: () => import("../views/event/Detail.vue"),
      },
    ],
  },
  {
    path: "/notice",
    name: "notice",
    components: {
      gnb: GNB,
      main: () => import("../views/board/Notice.vue"),
      footer: Footer,
    },
    children: [
      {
        path: "",
        name: "notice_list",
        component: () => import("../views/board/List.vue"),
      },
      {
        path: ":board_id",
        name: "notice_view",
        component: () => import("../views/board/View.vue"),
      },
    ],
  },
  {
    path: "/cart",
    name: "cart",
    components: {
      gnb: GNB,
      main: () => import("../views/product/Cart.vue"),
      footer: Footer,
    },
  },
  {
    path: "/category/:category_id",
    name: "category",
    components: {
      gnb: GNB,
      main: () => import("../views/product/Category.vue"),
      footer: Footer,
    },
  },
  {
    path: "/product/detail/:product_id",
    name: "detail",
    components: {
      gnb: GNB,
      main: () => import("../views/product/Detail.vue"),
      footer: Footer,
    },
  },
  {
    path: "/search",
    name: "search",
    components: {
      gnb: GNB,
      main: () => import("../views/product/Search.vue"),
      footer: Footer,
    },
  },
  {
    path: "/store/:seller_id",
    name: "store",
    components: {
      gnb: GNB,
      main: () => import("../views/product/Store.vue"),
      footer: Footer,
    },
  },
  {
    path: "/product/avatar",
    name: "avatar_product",
    components: {
      gnb: GNB,
      main: () => import("../views/product/Avatar.vue"),
      footer: Footer,
    },
  },
  {
    path: "/order/:invoice_token",
    name: "order",
    components: {
      gnb: GNB,
      main: () => import("../views/order/Order.vue"),
      footer: Footer,
    },
  },
  {
    path: "/order/complete",
    name: "order_complete",
    components: {
      gnb: GNB,
      main: () => import("../views/order/OrderComplete.vue"),
      footer: Footer,
    },
  },
  {
    path: "/order/mobile",
    name: "order_mobile",
    components: {
      gnb: GNB,
      main: () => import("../views/order/OrderPayMobile.vue"),
      footer: Footer,
    },
  },
  {
    path: "/mypage",
    components: {
      gnb: GNB,
      main: () => import("../views/mypage/MyPage.vue"),
      footer: Footer,
    },
    children: [
      {
        path: "",
        name: "mypage",
        component: () => import("../views/mypage/Dashboard.vue"),
      },
      {
        path: "account",
        name: "account",
        component: () => import("../views/mypage/Account.vue"),
      },
      {
        path: "order",
        name: "myorder",
        component: () => import("../views/mypage/Order.vue"),
      },
      {
        path: "order/detail/:order_id",
        name: "orderdetail",
        component: () => import("../views/mypage/OrderDetail.vue"),
      },
      {
        path: "point",
        name: "mypoint",
        component: () => import("../views/mypage/Point.vue"),
      },
      {
        path: "coupon",
        name: "mycoupon",
        component: () => import("../views/mypage/Coupon.vue"),
      },
      {
        path: "review",
        name: "myreview",
        component: () => import("../views/mypage/Review.vue"),
      },
      {
        path: "qna",
        name: "myqna",
        component: () => import("../views/mypage/Qna.vue"),
      },
      {
        path: "nft",
        name: "mynft",
        component: () => import("../views/mypage/NFT.vue"),
      },
    ],
  },
  {
    path: "/auth",
    redirect: "/auth/login",
    components: {
      main: () => import("../views/auth/Auth.vue"),
    },
    children: [
      {
        path: "login",
        name: "login",
        component: () => import("../views/auth/Login.vue"),
      },
      {
        path: "join",
        name: "join",
        component: () => import("../views/auth/Join.vue"),
      },
      {
        path: "find",
        name: "find",
        component: () => import("../views/auth/Find.vue"),
      },
      {
        path: "reset/:change_token",
        name: "reset",
        component: () => import("../views/auth/Reset.vue"),
      },
      {
        path: "agree",
        name: "agree",
        component: () => import("../views/auth/Agree.vue"),
      },
    ],
  },

  {
    path: "/policy",
    redirect: "/policy/term",
    components: {
      // gnb: GNB,
      main: () => import("../views/policy/Policy.vue"),
      footer: Footer,
    },
    children: [
      {
        path: "term",
        name: "term",
        component: () => import("../views/policy/Term.vue"),
      },
      {
        path: "privacy",
        name: "privacy",
        component: () => import("../views/policy/Privacy.vue"),
      },
    ],
  },

  {
    path: "/avatar",
    components: {
      main: () => import("../views/avatar/Avatar.vue"),
      footer: Footer,
    },
    children: [
      {
        path: "customize",
        name: "avatar_customize",
        component: () => import("../views/avatar/Customize.vue"),
      },
    ],
  },
  {
    path: "/avatar/intro",
    components: {
      gnb: GNB,
      main: () => import("../views/avatar/Intro.vue"),
      footer: Footer,
    },
  },

  {
    path: "/cs",
    components: {
      gnb: GNB,
      main: () => import("../views/cs/CS.vue"),
      footer: Footer,
    },
    children: [
      {
        path: "",
        name: "cs_info",
        component: () => import("../views/cs/Info.vue"),
      },
      {
        path: "faq",
        name: "faq",
        component: () => import("../views/cs/Faq.vue"),
      },
    ],
  },

  {
    path: "/partner/apply",
    components: {
      main: () => import("../views/partner/Apply.vue"),
      footer: Footer,
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 };
    }
  },
});

// 라우팅마다 타이틀 감지하여 변경
// router.afterEach((to) => {
//   if (typeof to.meta.title != "undefined" && to.meta.title != "") {
//     document.title = "We Know Them" + " - " + to.meta.title;
//   } else {
//     document.title = "We Know Them";
//   }
// });

export default router;
