<template>
  <div class="overlay">
    <div class="divLogo">
      <div class="logo_wrap link" href="/">
        <div class="group2">
          <div class="rect4"></div>
          <div class="rect5"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Spinner",
};
</script>

<style scoped>
.overlay {
  position: fixed;
  top: 0;
  left: 0;

  width: 100%;
  height: 100vh;

  background: rgba(0, 0, 0, 0.65);

  z-index: 99;
}
.logo_wrap {
  position: fixed;
  right: 50%;
  bottom: 50%;
  transform: translate(50%, 50%);

  width: 82px;
  max-width: 80%;
  padding: 0;
  margin: 0 auto;
  word-break: keep-all;
  word-wrap: normal;
  white-space: nowrap;
  font-size: 0;
  text-align: center;
}

.logo_wrap .group2 {
  display: block;
  position: relative;
  width: 100%;
  padding-top: 100%;
  animation: move_rotate 2s cubic-bezier(0.21, 0.8, 0.61, 0.28) infinite;
}

.logo_wrap .group2 > div {
  position: absolute;
  right: 50%;
  bottom: 50%;
  width: 30.06%;
  padding-top: 111.35%;
  background: #e74350;
}

.logo_wrap .group2 .rect4 {
  transform: translate(50%, 50%) rotate(45deg);
}

.logo_wrap .group2 .rect5 {
  transform: translate(50%, 50%) rotate(135deg);
}

@keyframes move_rotate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(720deg);
  }
}
</style>
