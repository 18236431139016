import axios from "axios";
import { Storage } from "../storage";

axios.interceptors.request.use(
  (config) => {
    const token = Storage.get_user_token();
    if (token && token != null && token != "") {
      config.headers["x-access-token"] = token; // 토큰 넣어서 보내기
    }

    config.withCredentials = true;

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => {
    // 응답에 토큰 갱신 헤더가 있으면 갱신하기
    let wkt_renew_token = response.headers["wkt_renew_token"];
    if (typeof wkt_renew_token != "undefined") {
      let parsed_json = JSON.parse(wkt_renew_token);

      let user_id = parsed_json.user_id;
      let user_email = parsed_json.user_email;
      let user_name = parsed_json.user_name;
      let user_country = parsed_json.user_country;
      let avatar_thumbnail = parsed_json.avatar_thumbnail;
      let user_token = parsed_json.token;

      Storage.set_user_info({
        user_id: user_id,
        user_email: user_email,
        user_name: user_name,
        user_country: user_country,
        avatar_thumbnail: avatar_thumbnail,
      });

      Storage.set_user_token(user_token);
      console.log("WKT Token renewed.");
    }

    // 세션도 종료되고 토큰이 만료된 경우
    let wkt_token_expired = response.headers["wkt_token_expired"];
    if (
      typeof wkt_token_expired != "undefined" &&
      wkt_token_expired.toString() == "true"
    ) {
      // 정보 없애기
      Storage.clear();
      console.log("WKT Token expired and cleared.");
    }

    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default axios;
