import { createApp } from "vue";
import { createI18n } from "vue-i18n";

import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "./api/axios.js";
import vueCookies from "vue-cookies";
import VueCryptojs from "vue-cryptojs";
import vue3GoogleLogin from "vue3-google-login";
import VueGtag from "vue-gtag";

import { createMetaManager, plugin as metaPlugin } from "vue-meta";

import { Storage } from "./storage";

import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";

import common from "@/util/common.js";
import messages from "@/i18n";

import Spinner from "@/components/Spinner.vue";

import "@/assets/css/common.css";

// i18n 기본 설정
const i18n = createI18n({
  locale: "ko",
  fallbackLocale: "en",
  legacy: false,
  messages,
});

const google_option = {
  clientId:
    "404955477949-pbq0v73vm0uou04vmpb1j8ih4f8kuq5e.apps.googleusercontent.com",
};

const metaManager = createMetaManager();

const app = createApp(App)
  .use(i18n)
  .use(store)
  .use(common)
  .use(router)
  .use(vueCookies)
  .use(VueCryptojs)
  .use(VueSweetalert2)
  .use(metaManager)
  .use(metaPlugin)
  .use(vue3GoogleLogin, google_option)
  .use(VueGtag, {
    config: { id: "G-BLK9TS4BF1" },
  });

app.component("Spinner", Spinner);
app.config.globalProperties.axios = axios;
app.config.globalProperties.storage = Storage;
app.mount("#app");
