<template>
  <div id="gnb" ref="gnb">
    <div class="inner">
      <div class="logo" @click="this.goto_home"></div>
      <ul class="menu">
        <li
          class="all_category"
          @mouseover="is_visible_category = true"
          @mouseleave="leave_category"
        >
          {{ this.$t("gnb.categories") }}
          <div
            class="category"
            v-show="is_visible_category"
            @mouseover="is_visible_category = true"
            @mouseleave="leave_category"
          >
            <ul class="level depth1">
              <li
                v-for="(value, index) in selected_category_depth1"
                :key="index"
                :class="{ selected: value.selected }"
                @mouseover="
                  over_category_item(value.category_id, value.children)
                "
                @click="click_category(value.category_id)"
              >
                {{ value.category_name }}
              </li>
            </ul>
            <ul class="level depth2" v-if="selected_category_depth2.length > 0">
              <li
                v-for="(value, index) in selected_category_depth2"
                :key="index"
                :class="{ selected: value.selected }"
                @mouseover="
                  over_category_item(value.category_id, value.children, 2)
                "
                @click="click_category(value.category_id)"
              >
                {{ value.category_name }}
              </li>
            </ul>
            <ul class="level depth3" v-if="selected_category_depth3.length > 0">
              <li
                v-for="(value, index) in selected_category_depth3"
                :key="index"
                :class="{ selected: value.selected }"
                @mouseover="
                  over_category_item(value.category_id, value.children, 3)
                "
                @click="click_category(value.category_id)"
              >
                {{ value.category_name }}
              </li>
            </ul>
            <ul class="level depth4" v-if="selected_category_depth4.length > 0">
              <li
                v-for="(value, index) in selected_category_depth4"
                :key="index"
                :class="{ selected: value.selected }"
                @mouseover="
                  over_category_item(value.category_id, value.children, 4)
                "
                @click="click_category(value.category_id)"
              >
                {{ value.category_name }}
              </li>
            </ul>
          </div>
          <!-- category -->
        </li>
        <li>
          <router-link to="/product/avatar">{{
            this.$t("gnb.avatar_coordi")
          }}</router-link>
        </li>
        <li>
          <router-link to="/new">{{ this.$t("gnb.new") }}</router-link>
        </li>
        <li>
          <router-link to="/ranking">{{ this.$t("gnb.ranking") }}</router-link>
        </li>
        <li>
          <router-link to="/event">{{ this.$t("gnb.event") }}</router-link>
        </li>
        <li>
          <router-link to="/notice">{{ this.$t("gnb.notice") }}</router-link>
        </li>
      </ul>
      <ul class="login">
        <li class="search">
          <input
            type="text"
            class="input_search"
            v-model="this.search_keyword"
            @keypress.enter="this.click_search"
          />
          <div class="search_button" @click="this.click_search"></div>
        </li>
        <li class="cart">
          <router-link to="/cart">
            <div class="link cart">
              <div class="count" v-if="cart_count > 0">
                {{ this.common.number_format(cart_count) }}
              </div>
            </div>
          </router-link>
        </li>
        <li v-if="!logged_in">
          <router-link to="/auth/login">
            <div class="link login"></div>
          </router-link>
        </li>
        <li v-if="logged_in">
          <div
            class="link logout"
            @click="try_logout"
            :tooltip="this.$t('gnb.logout')"
            flow="down"
          ></div>
        </li>
        <li v-if="logged_in">
          <router-link to="/mypage">
            <div
              class="link profile"
              :style="{ backgroundImage: 'url(' + this.avatar_image_url + ')' }"
            ></div>
          </router-link>
        </li>
      </ul>
    </div>
  </div>
  <div id="gnb_dummy" :style="{ height: this.gnb_height + 'px' }"></div>

  <div id="mobile_gnb">
    <ul>
      <li class="home" @click="this.goto_home"></li>
      <li class="category" @click="this.is_mobile_category_shown = true"></li>
      <!-- <li class="new" @click="this.$router.push('/new')"></li> -->
      <li class="avatar" @click="this.$router.push('/product/avatar')"></li>
      <li class="ranking" @click="this.$router.push('/ranking')"></li>
      <li
        class="login"
        @click="this.$router.push('/auth/login')"
        v-if="!logged_in"
      ></li>
      <li class="mypage" @click="this.$router.push('/mypage')" v-if="logged_in">
        <div
          class="profile"
          :style="{ backgroundImage: 'url(' + this.avatar_image_url + ')' }"
        ></div>
      </li>
    </ul>
  </div>

  <Transition>
    <div class="mobile_category" v-if="this.is_mobile_category_shown">
      <div class="close" @click="this.is_mobile_category_shown = false"></div>
      <h1>{{ this.$t("gnb.categories") }}</h1>
      <ul class="category">
        <li v-for="(value, index) in category_data" :key="index">
          <div class="header" @click="click_category(value.category_id)">
            {{ value.category_name }}
          </div>
          <ul class="subcategory">
            <li
              v-for="(value2, index2) in value.children"
              :key="index2"
              @click="click_category(value2.category_id)"
            >
              {{ value2.category_name }}
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </Transition>
</template>

<script>
export default {
  name: "GNB",
  data: function () {
    return {
      category_data: [], // 카테고리 전체 데이터

      selected_category_depth1: [], // 1차 선택 카테고리
      selected_category_depth2: [], // 2차 선택 카테고리
      selected_category_depth3: [], // 3차 선택 카테고리
      selected_category_depth4: [], // 4차 선택 카테고리
      is_visible_category: false, // 카테고리 보임 여부

      logged_in: this.storage.is_logged_in(), // 로그인 여부
      cart_count: this.storage.get_user_cart().length, // 장바구니 상품 수
      interval_timer: null, // 로그인 및 장바구니를 위한 인터벌 타이머

      // expand_sidebar: false, // 사이드바 펼침 여부 (모바일)
      search_keyword: "", // 검색어

      avatar_image_url: "", // 아바타 이미지
      gnb_height: 66,

      is_mobile_category_shown: false, // 모바일용 카테고리 보이기 여부
    };
  },
  mounted() {
    // 검색어가 있다면, 설정하기
    if (
      typeof this.$route.query.keyword != "undefined" &&
      this.$route.query.keyword.trim() != ""
    ) {
      this.search_keyword = this.$route.query.keyword;
    }

    this.load_category(); // 카테고리 전체 데이터 불러오기
    this.sync_user_cart_data(); // 장바구니 동기화

    this.clear_timer();
    this.start_timer(); // 인터벌 타이머 시작

    // 아바타 이미지 설정
    this.avatar_image_url = this.storage.get_user_avatar_url();

    // GNB 높이 설정
    if (typeof this.$refs.gnb != "undefined") {
      this.gnb_height = this.$refs.gnb.offsetHeight;
    }
  },
  beforeUnmount() {
    this.clear_timer();
  },
  methods: {
    // 타이머 시작
    start_timer: function () {
      let self = this;
      this.interval_timer = setInterval(function () {
        self.logged_in = self.storage.is_logged_in();
        self.cart_count = self.storage.get_user_cart().length;
      }, 1000);
    }, //-- start_timer

    // 타이머 지우기
    clear_timer: function () {
      if (
        typeof this.interval_timer != "undefined" &&
        this.interval_timer != null
      ) {
        clearInterval(this.interval_timer);
        this.interval_timer = null;
      }
    }, //-- clear_timer

    // 카테고리 데이터 가져오기
    load_category: function () {
      let self = this;
      this.axios
        .post(process.env.VUE_APP_API_URL + "/category/all")
        .then(function (response) {
          self.category_data = response.data;
          self.selected_category_depth1 = self.category_data;
        });
    }, //-- load_category

    // 마우스 커서 카테고리 떠날 때 핸들러
    leave_category: function () {
      this.selected_category_depth1.forEach(function (element) {
        element.selected = false;
      });
      this.selected_category_depth2.forEach(function (element) {
        element.selected = false;
      });
      this.selected_category_depth3.forEach(function (element) {
        element.selected = false;
      });
      this.selected_category_depth4.forEach(function (element) {
        element.selected = false;
      });

      this.selected_category_depth2 = [];
      this.selected_category_depth3 = [];
      this.selected_category_depth4 = [];
      this.is_visible_category = false;
    }, //-- leave_category

    // 마우스 카테고리 오버 이벤트 핸들러
    over_category_item: function (category_id, children, depth) {
      if (typeof depth == "undefined" || depth == null) depth = 1;

      if (depth == 1) {
        this.selected_category_depth2 = [];
        this.selected_category_depth3 = [];
        this.selected_category_depth4 = [];
        this.selected_category_depth2 = children;

        this.selected_category_depth1.forEach(function (element) {
          if (element.category_id == category_id) {
            element.selected = true;
          } else {
            element.selected = false;
          }
        });
      } else if (depth == 2) {
        this.selected_category_depth3 = [];
        this.selected_category_depth4 = [];
        this.selected_category_depth3 = children;

        this.selected_category_depth2.forEach(function (element) {
          if (element.category_id == category_id) {
            element.selected = true;
          } else {
            element.selected = false;
          }
        });
      } else if (depth == 3) {
        this.selected_category_depth4 = [];
        this.selected_category_depth4 = children;

        this.selected_category_depth3.forEach(function (element) {
          if (element.category_id == category_id) {
            element.selected = true;
          } else {
            element.selected = false;
          }
        });
      }
    }, //-- over_category_item

    // 카테고리 클릭 이벤트 핸들러
    click_category: function (category_id) {
      if (typeof category_id != "undefined" && category_id) {
        this.$router.push("/category/" + category_id);
      }
    }, //-- click_category

    // 장바구니 데이터 동기화
    sync_user_cart_data: function () {
      this.common.sync_user_cart();
    }, //-- sync_user_cart_data

    // 로그아웃 버튼 이벤트 핸들러
    try_logout: function (event) {
      event.stopPropagation();
      event.preventDefault();

      let self = this;

      this.$swal
        .fire({
          // title: this.$t("auth.confirm_logout"),
          text: this.$t("auth.confirm_logout"),
          icon: "question",
          showCancelButton: true,
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.axios
              .post(process.env.VUE_APP_API_URL + "/auth/logout")
              .finally(function () {
                // 토큰 및 사용자 정보 제거하기
                self.storage.clear();
                document.location.href = "/";
              });
          }
        }); //-- swal
    }, //-- try_logout

    // 검색 버튼 클릭 이벤트 핸들러
    click_search: function () {
      this.search_keyword = this.search_keyword.trim();
      if (this.search_keyword.length <= 1) {
        this.$swal.fire({
          text: this.$t("gnb.error_search_short"),
          icon: "warning",
        }); //-- swal
        return;
      }

      let param = {
        path: "/search",
        query: { keyword: this.search_keyword },
      };

      this.$router.push(param);
    }, //-- click_search

    // 메인으로 이동
    goto_home: function () {
      window.location.href = "/";
    }, //-- goto_home
  },
};
</script>

<style scoped>
#gnb {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  font-weight: 500;
  color: #333;
  background: #fff;
  border-bottom: 1px solid rgb(233, 236, 239);
  z-index: 10;
}
#gnb a {
  color: #000;
  text-decoration: none;
}
#gnb a:hover {
  color: #e74350;
  text-decoration: none;
}
#gnb a.router-link-exact-active {
  color: #000;
}
#gnb > .inner {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  width: 1200px;
  max-width: 100%;
  margin: 0 auto;
}
#gnb > .inner > .logo {
  display: block;

  width: 60px;
  height: 32px;
  background: url("../assets/img/logo_600.png") no-repeat center center;
  background-size: 100% auto;

  margin: 17px 0;
  margin-right: 24px;

  cursor: pointer;
}
#gnb > .inner > .menu {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  vertical-align: middle;
  list-style-type: none;

  margin: 0;
  padding: 0;
}
#gnb > .inner > .menu > li {
  position: relative;
  display: block;
  margin: 0;
  padding: 22px 1rem;
  font-size: 1.1rem;
  font-weight: 700;
}
#gnb > .inner > .menu > li.all_category {
  position: relative;
  margin-right: 1rem;
  padding-right: 2rem;
  padding-left: 2rem;

  cursor: pointer;
}
#gnb > .inner > .menu > li.all_category::after {
  position: absolute;
  right: 0;
  bottom: 50%;
  transform: translate(0, 50%);
  display: block;
  content: "";
  width: 1px;
  height: 30%;
  background: #ccc;
}

#gnb > .inner > .login {
  position: absolute;
  right: 16px;
  bottom: 50%;
  transform: translate(0, 50%);
  margin: 0;
  padding: 0;

  list-style-type: none;

  display: flex;
  justify-content: center;
  align-items: center;
}
#gnb > .inner > .login > li {
  position: relative;
  margin-left: 1.8rem;
  padding: 0;
  font-size: 1rem;
}
#gnb > .inner > .login > li.search {
  position: relative;
}
#gnb > .inner > .login > li.search > .input_search {
  border: none;
  border-bottom: 1px solid #333;
  border-radius: 0;

  width: 240px;
  padding-left: 0.2rem;
  padding-right: 2.8rem;
}
#gnb > .inner > .login > li.search > .input_search:active,
#gnb > .inner > .login > li.search > .input_search:focus {
  box-shadow: none;
  border-color: #e74350;
}
#gnb > .inner > .login > li.search > .search_button {
  position: absolute;
  right: 0;
  bottom: 50%;
  transform: translate(0, 50%);

  width: 28px;
  height: 28px;
  background-image: url("../assets/img/icon_search.svg");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: auto 90%;

  cursor: pointer;
}

#gnb > .inner > .login > li .link {
  width: 28px;
  height: 28px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: auto 90%;

  cursor: pointer;
}
#gnb > .inner > .login > li .cart {
  position: relative;
  background-image: url("../assets/img/icon_bag.svg");
}
#gnb > .inner > .login > li .cart > .count {
  position: absolute;
  right: 0;
  bottom: 0;
  transform: translate(calc(50% - 0.2rem), 0.15rem);

  padding: 0.12rem 0.32rem;

  border-radius: 2rem;
  font-size: 0.7rem;
  font-weight: 500;
  color: #fff;
  background: #e74350;
}
#gnb > .inner > .login > li .search {
  background-image: url("../assets/img/icon_search.svg");
}
#gnb > .inner > .login > li .login {
  background-image: url("../assets/img/icon_profile.svg");
}
#gnb > .inner > .login > li .logout {
  background-image: url("../assets/img/icon_logout.svg");
}
#gnb > .inner > .login > li .profile {
  width: 40px;
  height: 40px;
  background-color: #dedede;
  background-image: url("../assets/img/icon_profile.png");
  background-size: cover;
  overflow: hidden;
  border-radius: 50%;
}

#gnb > .inner > .menu > li.all_category > .category {
  position: absolute;
  top: 100%;
  left: 0;

  background: #fff;
  border: 1px solid #808080;

  display: flex;
  justify-content: flex-start;
  align-items: stretch;
}
#gnb > .inner > .menu > li.all_category > .category > ul {
  list-style-type: none;
  padding: 0;
  margin: 0;

  width: 180px;

  border-right: 1px solid #dedede;
}
#gnb > .inner > .menu > li.all_category > .category > ul:last-child {
  border-right: none;
}
#gnb > .inner > .menu > li.all_category > .category > ul > li {
  font-size: 1.1rem;
  font-weight: 400;

  padding: 0.8rem 1.2rem;
  margin: 0;

  cursor: pointer;
}
#gnb > .inner > .menu > li.all_category > .category > ul > li.selected,
#gnb > .inner > .menu > li.all_category > .category > ul > li:hover {
  background: #f2f2f2;
}
#gnb > .inner > .menu > li.all_category > .category > ul.depth1 > li {
  font-weight: 600;
}

#gnb_dummy {
  height: 67px;
}

/* #gnb .hamburger {
  display: none;
  width: 2rem;
  height: 2rem;

  position: absolute;
  right: 16px;
  bottom: 50%;
  transform: translate(0, 50%);
  margin: 0;

  background-image: url("../assets/img/img_hamburger.svg");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: auto 1.8rem;

  cursor: pointer;
} */

#mobile_gnb {
  position: fixed;
  display: none;
  bottom: 0;
  left: 0;

  width: 100%;

  margin: 0;
  padding: 0;

  z-index: 10;

  background: #fff;
  border-top: 1px solid #dedede;
}
#mobile_gnb > ul {
  display: flex;
  justify-content: center;
  align-items: stretch;

  list-style-type: none;
  margin: 0;
  padding: 0;
}
#mobile_gnb > ul > li {
  position: relative;
  width: calc(100% / 5);
  height: 3.4rem;

  background-position: center center;
  background-repeat: no-repeat;
  background-size: auto 50%;
}
#mobile_gnb > ul > li::before {
  content: "";
  display: block;
  padding-top: 100%;
}
#mobile_gnb > ul > li.home {
  background-image: url("../assets/img/icon_home.svg");
}
#mobile_gnb > ul > li.category {
  background-image: url("../assets/img/icon_category.svg");
}
#mobile_gnb > ul > li.new {
  background-image: url("../assets/img/icon_new.svg");
}
#mobile_gnb > ul > li.avatar {
  background-image: url("../assets/img/icon_menu_avatar.svg");
}
#mobile_gnb > ul > li.ranking {
  background-image: url("../assets/img/icon_ranking.svg");
}
#mobile_gnb > ul > li.login {
  background-image: url("../assets/img/icon_profile.svg");
}
#mobile_gnb > ul > li.mypage > .profile {
  position: absolute;
  right: 50%;
  bottom: 50%;
  transform: translate(50%, 50%);
  width: 32px;
  height: 32px;
  background-color: #dedede;
  background-size: cover;
  overflow: hidden;
  border-radius: 50%;
}

.mobile_category {
  position: fixed;
  display: block;

  top: 0;
  left: 0;

  width: 100%;
  height: 100vh;
  margin: 0;
  padding: 1rem 0;

  overflow-y: auto;

  background: #fff;

  z-index: 12;
}
.mobile_category > .close {
  position: absolute;
  top: 1rem;
  right: 1rem;
  width: 2rem;
  height: 2rem;

  background-image: url("../assets/img/icon_close.svg");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: auto 80%;

  cursor: pointer;
}
.mobile_category > h1 {
  font-size: 1.4rem;
  font-weight: 700;
  padding: 0;
  margin: 0 0 2rem 0;
  text-align: center;
}
.mobile_category > ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.mobile_category > ul > li {
  margin: 0 0 1rem 0;
  padding: 0;
}
.mobile_category > ul > li > .header {
  font-size: 1.1rem;
  font-weight: 700;
  padding: 0.6rem 0.8rem;

  border-top: 1px solid #efefef;
  border-bottom: 1px solid #efefef;
}
.mobile_category > ul > li > .subcategory {
  list-style-type: none;
  padding: 0;
  margin: 0;

  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  flex-wrap: wrap;
}
.mobile_category > ul > li > .subcategory > li {
  width: 33.33%;
  padding: 0.6rem 0.8rem;

  border-right: 1px solid #efefef;
  border-bottom: 1px solid #efefef;
}

@media (max-width: 800px) {
  #gnb > .inner {
    padding-left: 1rem;
  }
  #gnb > .inner > .logo {
    margin: 12px 0;
    width: 56px;
    height: 30px;
  }
  #gnb > .inner > .menu {
    display: none;
  }
  #gnb > .inner > .login {
    justify-content: flex-end;

    width: calc(100% - 120px);
  }
  #gnb > .inner > .login > li:not(.search):not(.cart) {
    display: none;
  }
  #gnb > .inner > .login > li.search {
    flex-grow: 1;
    margin: 0;
  }
  #gnb > .inner > .login > li.search > .input_search {
    width: 100%;
  }

  #mobile_gnb {
    display: block;
  }
}
</style>
