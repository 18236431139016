<template>
  <div class="not_found">
    <div class="inner">
      <h1 class="mono">404</h1>
      <h2>PAGE NOT FOUND</h2>
      <p>We cound't find what you are looking for</p>
      <button class="button red" @click="this.goto_main">
        {{ this.$t("common.button_main") }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "NotFound",
  methods: {
    goto_main: function () {
      location.href = "/";
    },
  },
};
</script>

<style scoped>
.not_found {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  min-height: 100vh;
  flex-direction: column;

  padding: 2rem 0;

  overflow-y: auto;

  background-color: #000;
  background-size: auto 100%;
  background-image: url("../assets/img/img_login_background.jpg");
  background-repeat: repeat;
  background-attachment: fixed;
  animation: 120s linear 0s infinite normal none running not_found_animation;
}
@keyframes not_found_animation {
  0% {
    background-position: 0px 0px;
  }
  100% {
    background-position: calc(-100vw + 100%) 0px;
  }
}
.not_found::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  background: rgba(0, 0, 0, 0.65);
  z-index: 1;
}

.not_found > .inner {
  position: relative;
  z-index: 2;

  text-align: center;
  width: 800px;
  max-width: 100%;
  color: #fff;
}
.not_found > .inner > h1 {
  font-size: 12rem;
  font-weight: 400;
  letter-spacing: 1.6rem;
  text-shadow: 0 0 3px rgba(0, 0, 0, 0.65);

  padding: 0;
  margin: 0 0 1rem 0;
}
.not_found > .inner > h2 {
  font-size: 3.2rem;
  font-weight: 700;
  text-shadow: 0 0 3px rgba(0, 0, 0, 0.65);

  padding: 0;
  margin: 0 0 1rem 0;
}
.not_found > .inner > p {
  font-size: 1.45rem;
  font-weight: 400;
  color: #ccc;
  text-shadow: 0 0 3px rgba(0, 0, 0, 0.65);

  padding: 0;
  margin: 0 0 3rem 0;
}
.not_found > .inner > button {
  margin: 0 auto;
}
</style>
