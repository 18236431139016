import country from "./country.json";
import axios from "@/api/axios.js";
import { Storage } from "@/storage";

const methods = {
  // 이메일 유효성 검증
  validate_email: (email) => {
    var re = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    return re.test(email);
  },

  // 호스트 이름 기반으로 국가 코드 (3글자) 가져오기
  get_country_code_from_url: () => {
    let hosts = window.location.host.split(".");

    try {
      if (typeof hosts[0] !== "undefined") {
        let host = hosts[0].toUpperCase().trim();
        for (let i = 0; i < country.length; i++) {
          if (host == country[i].code2) {
            return country[i].code3;
          }
        }
      }
    } catch (error) {
      return "KOR";
    }

    return "KOR";
  },

  get_country_name_by_code3: (code3) => {
    for (let i = 0; i < country.length; i++) {
      if (code3.toUpperCase() == country[i].code3.toUpperCase()) {
        return country[i].name;
      }
    }

    return "";
  },

  get_country_name_by_code2: (code2) => {
    for (let i = 0; i < country.length; i++) {
      if (code2.toUpperCase() == country[i].code2.toUpperCase()) {
        return country[i].name;
      }
    }

    return "";
  },

  get_country_code2_by_code3: (code3) => {
    for (let i = 0; i < country.length; i++) {
      if (code3.toUpperCase() == country[i].code3.toUpperCase()) {
        return country[i].code2;
      }
    }

    return "";
  },

  // 랜덤 아이디 생성하기
  generate_random_id: () => {
    let now = new Date();

    let year = now.getFullYear().toString().slice(-2).padStart(2, "0");
    let month = (now.getMonth() + 1).toString().padStart(2, "0");
    let day = now.getDate().toString().padStart(2, "0");
    let hour = now.getHours().toString().padStart(2, "0");
    let min = now.getMinutes().toString().padStart(2, "0");
    let sec = now.getSeconds().toString().padStart(2, "0");
    let rand = Math.floor(Math.random() * 1000)
      .toString()
      .padStart(3, "0");

    return parseInt(year + month + day + hour + min + sec + rand);
  },

  // 숫자 포매팅
  number_format: (num, fixed) => {
    let options = { style: "decimal" };

    if (typeof fixed != "undefined" && !isNaN(fixed)) {
      options.maximumFractionDigits = fixed;
    }

    let intl = new Intl.NumberFormat("ko-KR", options);

    return intl.format(num);
  },

  // 금액 출력 포맷
  currency_format: (num, currency) => {
    let options = {
      style: "decimal",
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    };

    let refined_currency = currency.toUpperCase().trim();

    switch (refined_currency) {
      case "KRW":
        options.minimumFractionDigits = 0;
        options.maximumFractionDigits = 0;
        break;
      case "SGD":
        options.minimumFractionDigits = 2;
        options.maximumFractionDigits = 2;
        break;
    }

    let intl = new Intl.NumberFormat("ko-KR", options);
    let formatted_num = intl.format(num);

    return refined_currency + " " + formatted_num;
  },

  // 날짜 파싱 (크로스 브라우징)
  date_parse: (str) => {
    try {
      if (typeof str == "undefined" || str == null || str == "") {
        return null;
      }

      let millis = Date.parse(str);
      if (!isNaN(millis)) {
        let ret = new Date(millis);
        return ret;
      }
    } catch (error) {
      console.log(error);
      return new Date();
    }
  },

  // 날짜 표기
  date_short_format: (date, code) => {
    if (typeof date == "undefined" || date == null || date == "") {
      return "";
    }

    let month_names = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sept",
      "Oct",
      "Nov",
      "Dec",
    ];

    let day = date.getDate();
    let month_index = date.getMonth();
    let year = date.getFullYear();

    if (typeof code == "undefined" || code == "KOR") {
      return (
        year +
        "-" +
        (month_index + 1).toString().padStart(2, "0") +
        "-" +
        day.toString().padStart(2, "0")
      );
    }

    return month_names[month_index] + " " + day + ", " + year;
  },

  // 날짜 표기
  date_long_format: (date, code) => {
    if (typeof date == "undefined" || date == null || date == "") {
      return "";
    }

    let prefix = methods.date_short_format(date, code);

    let hour_num = date.getHours();
    let postfix = "AM";
    if (hour_num >= 12) postfix = "PM";
    hour_num = hour_num % 12;
    hour_num = hour_num ? hour_num : 12; // the hour '0' should be '12'
    let hour = hour_num.toString().padStart(2, "0");
    let minute = date.getMinutes().toString().padStart(2, "0");

    return prefix + " " + hour + ":" + minute + " " + postfix;
  },

  // 이름 마스킹
  mask_name: (str_name) => {
    if (str_name.length > 2) {
      var original_name = str_name.split("");
      original_name.forEach(function (name, i) {
        if (i === 0 || i === original_name.length - 1) return;
        original_name[i] = "*";
      });
      var join_name = original_name.join();
      return join_name.replace(/,/g, "");
    } else {
      var pattern = /.$/; // 정규식
      return str_name.replace(pattern, "*");
    }
  },

  // 제품 이미지 경로 가져오기
  get_product_image_url: (product_id, str_images) => {
    let arr_images = str_images.split("|");
    if (arr_images.length) {
      return (
        process.env.VUE_APP_API_URL +
        "/resource/get/product/" +
        product_id +
        "/" +
        arr_images[0]
      );
    }

    return "";
  },

  // 장바구니 DB 데이터를 Storage에 싱크 맞추기
  sync_user_cart: () => {
    return new Promise(function (resolve, reject) {
      console.log("Syncing user cart data...");

      // 로그인 안되어 있으면 리턴
      if (!Storage.is_logged_in()) {
        resolve("no_login");
        return;
      }

      // DB에서 가져오기
      axios
        .post(
          process.env.VUE_APP_API_URL + "/cart/list",
          {
            user_id: Storage.get_user_id(),
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then(function (response) {
          let data = response.data;

          // 이상이 있으면 더이상 처리 안함
          if (typeof data.code != "undefined" && data.code >= 1000) {
            reject("axios_response_error");
            return;
          }

          let storage_cart_data = [];

          for (let i = 0; i < data.length; i++) {
            let item = {
              cart_id: data[i].cart_id,
              product_id: data[i].product_id,
              product_option: data[i].product_option,
              product_count: parseInt(data[i].product_count),
              product_option_combination_id:
                data[i].product_option_combination_id,
            };
            try {
              item.cart_timestamp = methods.date_parse(data[i].cart_date);
            } catch (e) {
              console.log(e);
            }

            storage_cart_data.push(item);
          } //-- for

          // 저장소에 데이터 동기화
          Storage.set_user_cart(storage_cart_data);

          resolve(response);
        })
        .catch(function (error) {
          reject(error);
        }); //-- axios
    });
  },
};

export default {
  install(Vue) {
    Vue.config.globalProperties.common = methods;
  },
};
